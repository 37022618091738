<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <Search v-model="search" returnObject :searchEnpoint="list.api" searchPlaceholder="Rechercher un essai">
          <template v-slot:suggestions="{ item }">
            <router-link :to="{ name: 'essai', params: { id: item.id } }">
              {{ item.nom }}
            </router-link>
          </template>
          <SelectExtended id="protocole" v-if="reload" v-model="search.filters.protocole.values" multiple
            :label="search.filters.protocole.label" optionKey="id" optionValue="titre" :items="protocoles" />

          <SelectExtended id="code_national" v-if="reload" v-model="search.filters.protocole__code_national.values"
            multiple :label="search.filters.protocole__code_national.label" optionKey="code_national" optionValue="code_national"
            :items="codesNationaux" />

          <SelectExtended id="entite" v-model="search.filters.protocole__entite.values" multiple search label="Entité"
            optionKey="id" optionValue="nom" apiEndpoint="entite" :apiParams="{ limit: 0 }" />

          <SelectExtended id="thematique" v-model="search.filters.protocole__thematique.values" multiple search
            label="Thématiques" optionKey="id" optionValue="designation" apiEndpoint="dictionnaire/thematique" />

          <SelectExtended id="culture" v-model='search.filters.protocole__culture.values' multiple search
            label="Cultures" optionKey="id" optionValue="nom" apiEndpoint="culture" :apiParams="{ limit: 0, sort: 'nom.ASC' }" />

          <SelectExtended id="annee" v-model="search.filters.protocole__annee.values" multiple search label="Année"
            optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'anneerecolte',
            )}/valeur`" />

          <SelectExtended id="parcelle" v-model="search.filters.parcelle.values" multiple :search="true"
            :label="search.filters.parcelle.label" optionKey="id" optionValue="designation" apiEndpoint="parcelle"
            :apiParams="{
              sort: `designation.ASC`,
              limit: 0
            }" />

          <SelectExtended id="technicien" v-model="search.filters.technicien.values" multiple :search="true"
            :label="search.filters.technicien.label" optionKey="id" optionValue="nom_complet" :apiParams="{
              sort: `prenom.ASC,nom.ASC`,
              limit: 0,
              filters: `entite.id:in(${children.join(',')})`,
            }" apiEndpoint="utilisateur" />

          <Radio v-model="search.filters.plate_forme.values" id="plate_forme" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.plate_forme.label" inputStyle="inline" />
        </Search>

        <List v-if="filterSeasonObj?.filters" :api="list.api" :header="list.headers" :items="list.col"
          :item-key="list.key" :target="list.target" :defaultFilter="filterSeasonObj" disableGroupActions
          disableCheckbox>

          <template v-slot:nom="{ item }">
            <template v-if="!item.protocole?.bpe">
              {{ item.nom }}
            </template>
            <div class="essai-bpe" v-else>
              <div>{{ item.nom }}</div>
              <div class="essai-bpe-icon">
                <SvgIcon name="bpe" size="lg" />
              </div>
            </div>
          </template>

          <template v-slot:protocole="{ item }">
            {{ item.protocole?.titre }}
          </template>

          <template v-slot:code_protocole="{ item }">
            {{ item.protocole?.code_national }}
          </template>

          <template v-slot:statut="{ item }">
            <div v-if="item.protocole?.statut?.uid === 'VALIDE'" class="essai-statut essai-statut--filled">
            </div>
            <div v-else class="essai-statut"></div>
          </template>

          <template v-slot:annee="{ item }">
            {{ item.protocole?.annee_recolte?.valeur }}
          </template>

          <template v-slot:entite="{ item }">
            {{ item.protocole?.entite?.nom }}
          </template>

          <template v-slot:culture="{ item }">
            <template v-if="item.protocole.cultures.length > 0">
              <div class="tags">
                <MiniTag v-for="culture in item.protocole.cultures" :key="culture.id" :id="culture.uid"
                  :text="culture.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:thematique="{ item }">
            {{ item.protocole?.thematique?.designation }}
          </template>

          <template v-slot:responsable="{ item }">
            {{ item.technicien.email }}
          </template>

          <template v-slot:actions="{ item }">
            <ToolDropdown>
              <MenuItem v-if="helperService.userHasPermission('essai_show')" :item-data="{
                label: 'Consulter',
                iconName: 'visibility',
                route: {
                  name: 'essaiChild',
                  params: {
                    id: item.id,
                  },
                }
              }" />

              <MenuItem :item-data="{
                label: 'Calendrier',
                iconName: 'calendar',
                route: {
                  name: 'essaiChildCalendar',
                  params: {
                    id: item.id,
                    from: $route.name
                  },
                }
              }" />
              <MenuItem :item-data="{
                label: 'Documents',
                iconName: 'export',
              }" @click="openModal('downloadFiles', item)" />
            </ToolDropdown>

          </template>
        </List>
      </Section>
    </div>
  </form>

  <!-- Telecharger un document -->
  <Modal title="Télécharger un document" :active="modal.downloadFiles" :data="modalData" size="lg"
    @modal-close="modal.downloadFiles = false">
    <template v-slot:modal-body="{ data }">
      <div class="grid">
        <Btn text="Calendrier de l'essai" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'calendrier')" />
        <Btn text="Bilan" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'bilan')" />
        <Btn text="Fiche parcellaire" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'parcelle-fiche')" />
        <Btn text="Plan d'accès" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'parcelle')" />
        <Btn text="Plan de l'essai en PDF" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="printPlan(data, 'pdf')" />
        <Btn text="Plan de l'essai en XLS" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="printPlan(data, 'xls')" />
        <Btn text="Modalités" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('xls', 'essai', data.id, 'modalites', { from_parent: 1 })" />
      </div>

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.downloadFiles = false" />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Search from '@/components/list/Search.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Radio from '@/components/form/Radio.vue'
import MiniTag from '@/components/base/MiniTag.vue'

export default {
  name: 'EssaisView',

  components: {
    MiniTag,
    Radio,
    MenuItem,
    ToolDropdown,
    Modal,
    Search,
    SelectExtended,
    Pagination,
    List,
    Btn,
    SvgIcon,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      children: [],
      reload: false,
      filterSeason: '',
      filterSeasonObj: {},
      displayList: false,
      protocoles: [],
      codesNationaux: [],
      search: {
        attributs: ['nom'],
        filters: {
          // Object
          protocole: {
            type: 'object',
            key: 'id',
            values: [],
            label: 'Protocole',
          },
          protocole__code_national: {
            type: 'object',
            key: 'code_national',
            values: [],
            label: 'Code national',
          },
          protocole__culture: {
            type: 'object',
            key: 'id',
            values: [],
            label: 'Cultures',
          },
          protocole__thematique: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Thématique',
          },
          protocole__annee: {
            type: 'relation',
            values: [],
            key: 'id',
            label: 'Année',
          },
          protocole__entite: {
            type: 'object',
            key: 'id',
            values: [],
          },
          parcelle: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Parcelle',
          },
          technicien: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Technicien',
          },
          // Booléan
          plate_forme: {
            type: 'boolean',
            values: [],
            label: 'Plateforme',
          },
        },
      },

      list: {
        api: {
          endpoint: 'essai/enfants',
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        target: {
          route: {
            name: 'essaiChild',
            paramsFromRoute: undefined,
            itemParams: { id: 'id' },
          },
        },
        headers: [
          { label: 'Numéro', isLink: true },
          { label: 'Nom', dataField: 'nom', isLink: true },
          { label: 'Protocole', hidden: 'tablet' },
          { label: 'Code National', hidden: 'tablet' },
          { label: 'Entité' },
          { label: 'Année', hidden: 'mobile' },
          { label: 'Culture', hidden: 'mobile' },
          { label: 'Thématique', hidden: 'tablet' },
          { label: 'Responsable', hidden: 'tablet' },
        ],
        col: ['numero', 'nom', 'protocole', 'code_protocole', 'entite', 'annee', 'culture', 'thematique', 'responsable'],
        key: 'id',
      },

      modalData: {},
      modal: {
        downloadFiles: false,
      },

      // modaliteFilterItems: [],
      componentKey: 0,
    }
  },
  created() {
    const filterSeason = this.protocolService.filterByAgriculturalSeason('id', false)
    this.filterSeasonObj = this.protocolService.filterByAgriculturalSeason('id', true, 'protocole.annee')
    this.filterSeason = filterSeason
    this.search.filters.protocole__annee.values = this.filterSeasonObj.years
    this.children = this.$store.state.auth.user.data.entite.enfants.map((t) => t.id)
  },
  mounted() {
    this.emitter.emit('list-update-active-filters')
    this.emitter.on('list-update-params', this.updateParams)
  },
  methods: {
    closeOpenModal(toClose, toOpen, data) {
      this.modal[toClose] = false
      this.modalData = data
      this.modal[toOpen] = true
    },

    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    printPlan(data, type) {
      let displayOptions = {
        id: null,
        displayGeo: true,
        displayComputedId: true,
        displayRepetition: true,
        displayModalityNumber: true,
        displayDesignation: true,
        displayHeaders: true,
        displayColors: true,
      }

      let off = null

      this.fetchService
        .get(`protocole/${data.protocole.id}/modalite?sort=ordre.ASC`, {
          limit: 0,
        })
        .then((reponseModalite) => {
          const modalities = reponseModalite.data
          const displayModalitiesOptions = modalities.map((t) => t.id)

          const localDisplayModalitiesOptions = JSON.parse(
            localStorage.getItem('displayPlanModalitiesOptions'),
          )
          const f = localDisplayModalitiesOptions.find(
            (element) => element.id === data.plan.id,
          )
          if (f) {
            off = this.getDifference(displayModalitiesOptions, f.modalities)
          }

          if (localStorage.getItem('displayPlanOptions')) {
            const localDisplayPlanOptions = JSON.parse(
              localStorage.getItem('displayPlanOptions'),
            )
            const found = localDisplayPlanOptions.find(
              (element) => element.id === data.plan.id,
            )
            if (found) {
              displayOptions = found
            }

            displayOptions.modalites_off = off
          }

          const printOptions = {
            position_geographique: displayOptions.displayGeo ? 1 : 0,
            modalite_bloc: displayOptions.displayComputedId ? 1 : 0,
            numero_ligne: displayOptions.displayHeaders ? 1 : 0,
            numero_colonne: displayOptions.displayHeaders ? 1 : 0,
            numero_modalite: displayOptions.displayModalityNumber ? 1 : 0,
            numero_bloc: displayOptions.displayRepetition ? 1 : 0,
            libelle_modalite: displayOptions.displayDesignation ? 1 : 0,
            couleurs: displayOptions.displayColors ? 1 : 0,
          }

          if (displayOptions.modalites_off !== null) {
            printOptions.modalites_off = displayOptions.modalites_off
          }

          this.fileService.exportFile(type, 'essai', data.id, 'plan', printOptions)
        })
    },

    getDifference(array1, array2) {
      return array1.filter((object1) => !array2.some((object2) => object1 === object2))
    },

    async updateParams(params) {
      await this.loadProtocole(params?.filters)
    },

    async loadProtocole(filters) {
      this.reload = false
      this.protocoles = []
      let localFilters = ''

      if (filters) {
        localFilters = filters
      }
      const response = await this.fetchService.get('essai/enfants', { limit: 0, filters: localFilters })
      const tmpProto = []
      response.data.forEach((element) => {
        tmpProto[element.protocole.id] = element
      })
      tmpProto.forEach((element) => {
        // eslint-disable-next-line
        element.protocole.titre = `${element.protocole.titre} (${element.protocole?.code_national ? element.protocole?.code_national : element.protocole?.id})`
        this.protocoles.push(element.protocole)
        if (element.protocole.code_national) {
          this.codesNationaux.push(element.protocole)
        }
      })
      this.reload = true
      console.log('protocoles', filters, this.protocoles)
    },
  },
}
</script>

<style lang="scss" scoped>
.essai-statut {
  height: 15px;
  width: 15px;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  transform: rotate(45deg);

  &--filled {
    border: 1px solid $color-primary;
    background: $color-primary;
  }
}
</style>
